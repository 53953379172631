/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */
@tailwind utilities;

@font-face {
  font-family: 'Brandon';
  font-style: light;
  font-weight: 300;
  src: local(''), url('../public/assets/fonts/BrandonText-Light.woff2') format('woff2');
}
@font-face {
  font-family: 'Brandon';
  font-style: italic;
  font-weight: 300;
  src: local(''), url('../public/assets/fonts/BrandonText-LightItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Brandon';
  font-style: black;
  font-weight: 900;
  src: local(''), url('../public/assets/fonts/BrandonText-Black.woff2') format('woff2');
}

@font-face {
  font-family: 'Brandon';
  font-style: medium;
  font-weight: 500;
  src: local(''), url('../public/assets/fonts/BrandonText-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Brandon';
  font-style: bold;
  font-weight: 700;
  src: local(''), url('../public/assets/fonts/BrandonText-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Brandon';
  font-style: italic;
  font-weight: 700;
  src: local(''), url('../public/assets/fonts/BrandonText-BoldItalic.woff2') format('woff2');
}

:root {
  @apply bg-redOne;
  @apply font-black;
  @apply text-white;
  @apply overflow-x-hidden;
}
/* main {
  @apply max-w-md;
  @apply m-auto;
  @apply: h-auto;
}

*/
h1 {
  @apply font-bold;
  @apply text-darkGold;
  @apply text-[1rem];
  @apply tracking-wider;
  @apply pb-2;
}

h2 {
  @apply font-black;
  @apply text-[1.375rem];
  @apply text-white;
  @apply tracking-wider;
}

h3 {
  @apply font-black;
  @apply text-darkGold;
  @apply text-[1.25rem];
}

h4 {
  @apply font-light;
  @apply text-darkGold;
  @apply text-[0.875rem];
  @apply italic;
}

html,
body {
  overflow-x: hidden;
}

body {
  position: relative;
}

input::placeholder {
  @apply text-[#89706e];
  @apply opacity-100;
  @apply font-light;
}

textarea::placeholder {
  @apply p-2;
  @apply text-[#89706e];
  @apply opacity-100;
  @apply font-light;
}

@layer base {
  input[type='time']::-webkit-calendar-picker-indicator {
    background: none;
    border: none;
  }
}

::-webkit-scrollbar {
  display: none;
}
/* tailwind does not support inherit. used for fixed elements. */
.inheritWidth {
  width: inherit;
  max-width: inherit;
}

.PhoneInput {
  background-color: rgb(71 18 16 / var(--tw-bg-opacity));
  padding-left: 0.75rem;
}
.PhoneInputCountry {
  width: 30px;
}

.PhoneInputInput {
  background-color: rgb(71 18 16 / var(--tw-bg-opacity));
  padding: 0.5rem 0;
}

.quick-edit-content {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 500px;
  background-color: #220908;
  transition: transform 0.3s ease-in-out;
  transform: translateY(100%);
  z-index: 99;
}

.quick-edit-content-active {
  transform: translateY(0);
}

.quick-edit-content-wrapper {
  min-height: 500px;
}

.qe-button-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

*:not(input):not(textarea) {
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none;
}